import favicon from './images/favicon.ico';
import EPT_Logo_Icon_Only from './images/EPT Logo 2023 (Icon Only - Web) (374 x 176 px).png';
import EPT_Logo from './images/EPT Logo 2023 (Web) (800 x 176 px).png';
import OPT_Logo_Icon_Only from './images/OPT Logo (Icon Only - Web) (360 x 170 px).png';
import OPT_Logo from './images/OPT Logo 2023 (Web) (800 x 171 px).png';
import Email_Signature_EPT from './images/Logo - Email Signature - EPT.png';

import classes from './App.module.css';

function App() {
	return (
		<div>
			<header>
				<h2>EPT & OPT</h2>
				<h3>Logos & Colors</h3>
			</header>
			<section>
				<div>
					Roboto Font: @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
				</div>
				<br />
				<br />

				<div style={{ backgroundColor: '#bfcfe827', color: 'black' }}>Background Blue: #bfcfe827</div>
				<br></br>
				<div style={{ backgroundColor: '#bfcfe871', color: 'black' }}>Header Background Blue: #bfcfe871</div>
				<br />
				<br />

				<div style={{ backgroundColor: '#5b7fb4', color: 'white' }}>Medium Blue: #5b7fb4</div>
				<br />
				<br />

				<div style={{ backgroundColor: '#1f2253', color: 'white' }}>Dark Blue: #1f2253</div>
				<br />
				<br />

				<img src={Email_Signature_EPT} alt='Email Signature Logo - EPT' />
				<div>Email Signature Logo - EPT</div>
				<br />
				<br />

				<img src={favicon} style={{ width: '50px' }} alt='EPT Favicon' />
				<div>favicon</div>
				<br />
				<br />

				<img src={EPT_Logo_Icon_Only} alt='EPT Icon' />
				<div>374 x 176 px</div>
				<br />
				<br />

				<img src={OPT_Logo_Icon_Only} alt='OPT Icon' />
				<div>360 x 170 px</div>
				<br />
				<br />

				<img src={EPT_Logo} alt='EPT Logo' />
				<div>800 x 176 px</div>
				<br />
				<br />

				<img src={OPT_Logo} alt='OPT Logo' />
				<div>800 x 171 px</div>
				<br />
				<br />
			</section>
		</div>
	);
}

export default App;
